<!-- 
The primary page to search for specific accounts.
-->


<template>
    <v-container v-if="$store.state.user">
        <!-- Page Title -->
        <v-row class="text-center mt-10">
            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">Remover User Account <v-icon large>mdi-account-remove</v-icon></h1>
                <p>Please select a user to delete.</p>
            </v-col> 
        </v-row>


        <!-- Text field to input username that the user would like to delete/remove -->
        <div>
            <v-card class="mx-auto my-12 text-center" max-width="674">
                <template slot="progress">
                    <v-progress-linear color="deep-purple" height="55" indeterminate></v-progress-linear>
                </template>

                <v-card-text>
                    <v-row align="center" class="mx-0"></v-row>
                    <div class="my-4 text-subtitle-1"></div>
                    <div>
                        <p>Please enter the username associated with the user account you wish to remove.</p>
                    </div>
                </v-card-text>

                <v-card-text>
                    <!-- <v-text-field required v-model="providedUsername" label="Username" solo></v-text-field> -->
                    <v-select :items="items" v-model="providedUsername" label="Username" solo></v-select>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <!-- <v-btn color="primary" @click="this.dialog = true">Submit</v-btn> -->
                        <template>
                            <v-row justify="center">
                                <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent max-width="600">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  color="error" dark v-bind="attrs" v-on="on">Delete User</v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar color="#4682b4" dark></v-toolbar>
                                    <v-card-text>
                                        <div class="text-center text-p pa-12">
                                            Are you sure you want to delete this user account?
                                            <br>
                                            This can't be undone.
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-center">
                                        <v-btn color="error" text @click="this.deleteUser">Yes</v-btn>
                                        <v-btn color="#4682b4" text @click="dialog = false">Cancel</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                            </v-row>
                        </template>
                    </v-col>
                </v-card-actions>
            </v-card>
        </div>


        <!-- Confirmation Modal -->
        <template v-slot:default="dialog">
            <v-card>
                <v-toolbar color="#4682b4" dark></v-toolbar>
                <v-card-text>
                    <div class="text-center text-p pa-12">
                        Are you sure you want to delete this user account?
                        <br>
                        This can't be undone.
                    </div>
                </v-card-text>

                <!-- Buttons within confirmation modal -->
                <v-card-actions class="justify-center">
                    <v-btn text @click="dialog.value = this.deleteUser">Yes</v-btn>
                    <v-btn text @click="dialog.value = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </template>


        <!-- Snackbar for failed search -->
        <div class="text-center ma-2">
            <v-snackbar v-model="snackbar" shaped>
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
    name: 'deleteUser',
    data () {
        return {
            dialog: false,
            person: null,
            personID: null,
            providedUsername: "",
            response: null,

            // The following two variables will be used later for dropdown of all registered user accounts
            items: [],
            allUsers: [],
            searchChoice: null,

            // Snackbar Variables
            snackbar: false,
            text: `Unable to delete user with the provided information.  Please try again.`
        }
    },

    async mounted() {
        // Get all login usernames from DB and add to usernames array (used later to verify valid username entry)
        this.allUsers = await AuthenticationService.getAllUsers()
        this.allUsers.data.rows.forEach(element => {
            this.items.push(element.login);
        })       
    },

    methods: {
        // Search database with provided first and last name
        async deleteUser() {

            try {
                const response = await AuthenticationService.deleteUser({ login: this.providedUsername })
                this.response = response;
            } catch (error) { this.error = error.response.data.error }

            if (this.response.data.rows.affectedRows == 0) {
                // Reset form input and display failed delete snackbar
                this.firstName = "";
                this.lastName = "";
                this.snackbar = true; 
            } else { this.$router.push({ path: '/LogIn' }) }
        }
    }

}
</script>